<template>
        
        <v-dialog v-model="dialog" persistent max-width="450">
            
            <v-card>
            <v-card-title class="">
            <span class="h6">Додати коментар до будинку</span>
            </v-card-title>
             <div  class="text-center mb-10">
                <v-chip label outlined color="primary lighten-1">
                    <v-icon left>
                        mdi-home-outline
                    </v-icon>
                        {{ street_name || form_data.street_name  }}, {{ form_data.house }}{{ form_data.letter }}
                        
                </v-chip>          
            </div>
            
           
            <v-card-text>
                <v-form 
                    ref="form_comment"
                    v-model="valid"
                    lazy-validation>
                        <v-row>
                            <v-col v-if="is_global"  cols="12" class="py-2">
                                <SearchStreet  v-on:search-new-id="setSearchValue" :denseForm="true" :outlinedForm="true" :clearSearchStreet="clearSearchStreet" />
                            </v-col>
                            <v-col v-for="field in fields" :key="field.name" cols="12" class="py-2">
                                <v-select v-if="field.type == 'select'"
                                dense
                                outlined
                                hide-details="auto"
                                :label="field.title"
                                v-model="form_data[field.name]"
                                :name="field.name"
                                :rules="field.rules && textRules || notRules"
                                :items="field.values"
                                item-text="name"
                                item-value="id"
                                :disabled="field.disabled"
                                :clearable="field.clearable"
                                >
                                </v-select>

                                <v-textarea v-else-if="field.type == 'textarea'"
                                    dense
                                    rows="3"
                                    outlined
                                    hide-details="auto"
                                    :label="field.title"
                                    v-model="form_data[field.name]"
                                    :name="field.name"
                                    :rules="field.rules && textRules || notRules"
                                    :type="field.type"
                                    :disabled="field.disabled"
                                ></v-textarea>
                                
                                <v-text-field v-else
                                dense
                                outlined
                                hide-details="auto"
                                :label="field.title"
                                v-model="form_data[field.name]"
                                :name="field.name"
                                :rules="field.rules && textRules || notRules"
                                :type="field.type"
                                :disabled="field.disabled"
                                >
                                </v-text-field>
                            </v-col>
                            <v-col  cols="12" class="py-2">
                                <v-file-input
                                    v-model="file"
                                    prepend-icon=""
                                    id="myFile"
                                    dense
                                    outlined
                                    accept="image/png, image/jpeg, image/bmp"
                                    chips
                                    label="Зображення"
                                ></v-file-input>
                            </v-col>
                        </v-row>
                </v-form>
            </v-card-text>
            <v-card-actions>
                <v-btn color="primary lighten-1" text @click="closeDialog()">Закрити</v-btn>
                <v-spacer />
                <v-btn color="primary" :class="$vuetify.theme.dark ? '' : 'lighten-1'" :loading="loading" @click="sendForm">Додати</v-btn>
                
                
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
    
    import axios from "axios"
    import { mapGetters } from "vuex"
	export default {
        name: 'modal-add-comment-house-set',
        props: ['is_global'],
        components: {
            SearchStreet: () => import('@/components/Tools/SearchStreet.vue')
        },
		data: () => ({
            file: [],
            street_name: '',
            house: '',
            letter: '',
            clearSearchStreet: 0,
            tab: null,
            loading:false,
            dialog: false,
            valid: true,
            item: null,
            form_data: {},
            fields: [],
            fieldsSimple: [
                {title: "Тип",     name: "comment_subj",     rules: true,  type: "select",   disabled: false, value: "", values: []},
                {title: "Опис",    name: "comment",     rules: true,  type: "textarea", disabled: false, value: "" },

            ],
            fieldsGlobal: [
                {title: "Будинок",     name: "house",     rules: true,  type: "number",   disabled: false, value: "", values: []},
                {title: "Літера",     name: "letter",     rules: false,  type: "select",   disabled: false, value: "", values: ['а','б','в','г','д','е','ж','з','и','к','л','м','н','о','п']},
                {title: "Тип",     name: "comment_subj",     rules: true,  type: "select",   disabled: false, value: "", values: []},
                {title: "Опис",    name: "comment",     rules: true,  type: "textarea", disabled: false, value: "" },

            ],
            textRules: [
                v => !!v || "Це поле обов'язкове",
            ],
            notRules: [
                v => !!v || true ,
            ],
            
        }),
        computed: {
            ...mapGetters({
                user: "auth/user",
                job_comment_type_house: "job_types/comment_type_house"
            }),
        },
        mounted(){
            this.fields = this.is_global && this.fieldsGlobal || this.fieldsSimple
            this.createJobTypes()
        },
        watch: {
            form_data(){
                console.log("update form_data")
            },
            file(){
                console.log('file: ',this.file)
                
            }
        },
        methods: {
            show(item){
                //this.item = item
                this.file = []
                this.form_data = item
                
                this.dialog = true;
            },
            validate () {
                if (this.$refs.form_comment.validate()) {
                    this.snackbar = true;
                }
            },
            reset () {
                this.$refs.form_comment && this.$refs.form_comment.reset();
                //this.$refs.form_close && this.$refs.form_close.reset();
            },
            closeDialog (){
                this.form_data = {}
                this.reset() 
                this.dialog = false
                //console.log(this.tab)
                
            },
            createJobTypes(){
                this.fields.find(x => x.name == "comment_subj").values = this.job_comment_type_house
            },
            setSearchValue(val, name){
                this.form_data.street = val
                this.form_data.street_name = name
                this.street_name = name
                console.log(val, name)
            },
            
            async createFormData(){
                const body = new FormData()
                this.file && body.append('pictures', this.file)
                
                for (let i in this.form_data){
                    this.form_data[i] && body.append(i, this.form_data[i])
                }
                //body.append(...this.form_data)
                return body
            },
            async sendForm() {
                //console.log(this.form_data)
                if(this.$refs.form_comment.validate()){
                    const body = await this.createFormData()
                    this.loading = true;
                    axios({
                        headers: { "Content-type": "application/json; charset=UTF-8" },
                        method: "POST",
                        url: "/api/service/house_comments/",
                        data: body,
                    })
                    .then(() => {
                        //console.log(response);
                        this.$emit('update-job');
                        this.$router.app.$snack.show("Коментар для будинку додано!");
                        this.closeDialog()
                    })
                    .catch(err => {
                        //reject(err)
                        //console.log(err);
                        this.$router.app.$sheet.show("Помилка!", err);
                        
                        //console.log(this.$route.query.next)
                    })
                    .finally(() => (this.loading = false))
                }
            },
            
            
            
        }
    }
</script>