<template>
        
        <v-dialog v-model="dialog" persistent max-width="450">
            
            <v-card>
            <v-card-title class="">
            <span class="h6">Редагування коментаря</span>
            </v-card-title>
             <div  class="text-center mb-10">
                <v-chip label outlined color="primary lighten-1">
                    <v-icon left>
                        mdi-home-outline
                    </v-icon>
                        {{ form_data.street_name  }}, {{ form_data.house }}{{ form_data.letter }}
                        
                </v-chip>          
            </div>
            
           
            <v-card-text>
                <div v-if="this.form_data.pictures" class="d-flex justify-center mb-5">
                <v-img
                    
                    max-height="100"
                    max-width="200"
                    :src="form_data.pictures"
                    >
                </v-img>
                </div>
                <v-form 
                    ref="form_comment"
                    v-model="valid"
                    lazy-validation>
                        <v-row>
                            <v-col v-for="field in fields" :key="field.name" cols="12" class="py-2">
                               

                                <v-textarea v-if="field.type == 'textarea'"
                                    dense
                                    rows="3"
                                    outlined
                                    hide-details="auto"
                                    :label="field.title"
                                    v-model="form_data[field.name]"
                                    :name="field.name"
                                    :rules="field.rules && textRules || notRules"
                                    :type="field.type"
                                    :disabled="field.disabled"
                                ></v-textarea>
                                
                                <v-text-field v-else
                                dense
                                outlined
                                hide-details="auto"
                                :label="field.title"
                                v-model="form_data[field.name]"
                                :name="field.name"
                                :rules="field.rules && textRules || notRules"
                                :type="field.type"
                                :disabled="field.disabled"
                                >
                                </v-text-field>
                            </v-col>
                            <v-col  cols="12" class="py-2">
                                <v-btn block  v-if="form_data.pictures" @click="form_data.pictures = ''">
                                    Видалити зображення
                                </v-btn>
                                <v-file-input
                                    v-else
                                    v-model="file"
                                    prepend-icon=""
                                    id="myFile"
                                    dense
                                    outlined
                                    accept="image/png, image/jpeg, image/bmp"
                                    chips
                                    label="Зображення"
                                    clearable
                                ></v-file-input>
                            </v-col>
                        </v-row>
                </v-form>
            </v-card-text>
            <v-card-actions>
                <v-btn color="primary lighten-1" text @click="closeDialog()">Закрити</v-btn>
                <v-spacer />
                <v-btn color="primary" :class="$vuetify.theme.dark ? '' : 'lighten-1'" :loading="loading" @click="sendForm">Змінити</v-btn>
                
                
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
    
    import axios from "axios"
    import {mapGetters} from 'vuex'
	export default {
        name: 'modal-change-comment-house-set',
		data: () => ({
            file: null,
            loading:false,
            dialog: false,
            valid: true,
            form_data: {},
            fields: [
                {title: "Тип",     name: "comment_subj",     rules: true,  type: "text",   disabled: true, value: "", values: []},
                {title: "Опис",    name: "comment",     rules: true,  type: "textarea", disabled: false, value: "" },
            ],
            textRules: [
                v => !!v || "Це поле обов'язкове",
            ],
            notRules: [
                v => !!v || true ,
            ],
            
        }),
        computed: {
            ...mapGetters({
                user: "auth/user",
                job_comment_type_house: "job_types/comment_type_house"
            }),
        },
        mounted(){
            this.createJobTypes()
        },
        watch: {
            //
        },
        methods: {
            show(item){
                console.log(item)
                this.form_data = item
                this.dialog = true
            },
            validate () {
                if (this.$refs.form_comment.validate()) {
                    this.snackbar = true
                }
            },
            reset () {
                this.$refs.form_comment && this.$refs.form_comment.reset()
            },
            closeDialog (){
                this.form_data = {}
                this.reset() 
                this.dialog = false
                
            },
            createJobTypes(){
                this.fields.find(x => x.name == "comment_subj").values = this.job_comment_type_house
            },
            
            async createFormData(){
                const body = new FormData()
                if(this.file) body.append('pictures', this.file)
                else if(!this.form_data.pictures) body.append('pictures', '')

                body.append('comment', this.form_data.comment)
                
                return body
            },
            async sendForm() {
                //console.log(this.form_data)
                if(this.$refs.form_comment.validate()){
                    const body = await this.createFormData()
                    this.loading = true;
                    axios({
                        headers: { "Content-type": "application/json; charset=UTF-8" },
                        method: "PUT",
                        url: `/api/service/house_comments/${this.form_data.id}/`,
                        data: body,
                    })
                    .then(() => {
                        //console.log(response);
                        this.$emit('update-job');
                        this.$router.app.$snack.show("Коментар для будинку змінено!");
                        this.closeDialog()
                    })
                    .catch(err => {
                        //reject(err)
                        //console.log(err);
                        this.$router.app.$sheet.show("Помилка!", err);
                        
                        //console.log(this.$route.query.next)
                    })
                    .finally(() => (this.loading = false))
                }
            },
            
            
            
        }
    }
</script>